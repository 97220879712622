import $ from "jquery";


// Execute functions / classes on document ready
$(document).ready( function () {

});

// Execute functions / classes on document load
document.addEventListener("DOMContentLoaded", function() {

});

window.addEventListener('resize', () => {

});